import { useEffect, useState, useRef, useCallback } from 'react'
import chevronUp from '../../assets/chevron-up.svg'
import chevron from '../../assets/chevron.svg'
import { useSelector } from 'react-redux'
import { TWS_API_URL } from '../../environment/apis.config'
import Toast, { toastPosition, toastType } from '../Toast/Toast'
import { toPng } from 'html-to-image'
import { getSensorData } from '../../api/SensorDetailsAPIService'
import { useLocation } from 'react-router-dom'

const CurrentWeatherTab = () => {
  const [opened, setOpener] = useState(false)
  const [content, setContent] = useState(<></>)
  const [transition, setTransition] = useState(
    'flex flex-row translate-x-52 transition-all duration-500'
  )
  const [weatherData, setWeather] = useState({})
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [type, setToastType] = useState(toastType.success)
  const [loaded, setLoad] = useState(false)
  const [thresholds, setThresholds] = useState({})
  const elementRef = useRef()
  const location = useSelector((state) => state.user.defaultLocation)
  const url = `${TWS_API_URL}/weatherobs`
  const thresh_url = `${TWS_API_URL}/customthreshold`
  const toastCallbackFn = () => {
    setShowToast(false)
    setToastMessage('')
  }
  const path = useLocation();
  // Weatherobs API call
  const getData = async () => {
    try {
      var requestBody : Array<any> = await getSensorData()
      var intelToken = []
      var tempestToken = []
      requestBody.forEach(element => {
        if(element.sensorType == "Intellisense"){
          intelToken.push(element.identity)
        }
        else if(element.sensorType == "Tempest"){
          tempestToken.push(element.identity)
        }
      });
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lat: location.latitude,
          long: location.longitude,
          inteldevice: intelToken,
          tempestToken: tempestToken,
        }),
      })
      const data = await response.json()
      if (data.message == 'Not Authorized.') {
        setToastType(toastType.error)
        setToastMessage('User not authorized')
        setShowToast(true)
      } else if (
        data.message == 'No weather data at this location' ||
        data.features.length == 0 ||
        Object.keys(data.features[0].properties).length == 0
      ) {
        setToastType(toastType.error)
        setToastMessage('No weather data availabe in the current area')
        setShowToast(true)
      } else {
        const threshStatus =  await getThreshold()
        if(!threshStatus){
          setToastType(toastType.error)
          setToastMessage('Due to your expired subscription, this action cannot be performed. Please renew your subscription from the profile menu and try again.')
          setShowToast(true)
        }
        else{
          setLoad(true)
          setWeather({
            ...data.features[0].properties,
          })
        }
      }
    } catch (error) {
      console.log(error)
      setToastType(toastType.error)
      setToastMessage(error.message)
      setShowToast(true)
    }
  }
  // Custom threshold API call
  const getThreshold = async() => {
    try {
     const response = await fetch(thresh_url, {
        method: 'POST',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        }
      })
      const threshData = await response.json()
      setThresholds(threshData)
      return threshData.message ?  false : true
    } catch (error) {
      console.log(error);
      return false
    }
  }
  // Util dictionaries
  const param_map: any = {
    temperature: 'Temp',
    Kp_index: 'Kp-Index',
    visibility: 'Visibility',
    ceiling: 'Ceiling',
    wind_speed: 'Wind Speed',
    wind_gust: 'Wind Gust',
    wind_direction: 'Wind Direction',
    cloud_cover: 'Cloud Cover',
    altimeter: 'ALT',
    dewpoint_temperature: 'DP Temp',
    relative_humidity: 'Relative Humidity',
  }
  const thresh_key: any ={
    wind_gust:"surfaceWindGust",
    ceiling:"ceilingHeight",
    visibility: "visibility",
    wind_speed:"surfaceWind"
  }
  const units_map: any ={
    "F" : "F",
    "none" : "",
    "Percent" : "%",
    "Feet" : "ft",
    "Knots" : "kts",
    "C" : "C",
    "Degrees" : "°",
    "inHg" : "inHg",
    "SM" : "sm",
    "MPH" : "mph",
    "MPS" : "mps"
  }
  // Operator functionality for color coding
  function operator(op:String, num1: Number, num2: Number) {
    switch(op){
      case "<":
        return num1 < num2;
      case ">":
        return num1 > num2;
      case "<=":
        return num1 <= num2;
      case ">=":
        return num1 >= num2;
      default:
        return true;
    }
  }

  const getThresholdColor = (val: Number, threshs: Object) => {
    var color_string:string
    var highriskVal = Number(threshs["currentHighRisk"][1])
    var margriskVal = Number(threshs["currentMarginalRisk"][1])
    var highRishOp = threshs["currentHighRisk"][0]
    var margRiskOp = threshs["currentMarginalRisk"][0]
    if(operator(highRishOp, val, highriskVal)){
      color_string =  "bg-red-500";
    }
    else if(operator(margRiskOp, val, margriskVal)){
      color_string = "bg-yellow-500"
    }
    else{
      color_string = "bg-green-500"
    }
    return color_string
  }

  // Color mapping based on custom thresholds
  const color_maping = (key: string, val: any, units: any, loaded:boolean) => {
    const base_string = 'm-1 h-3.5 w-3.5 '
    var color_string = "bg-gray-500"
    let value : Number
    if(!loaded || !["temperature", "ceiling", "visibility", "Kp_index", "wind_speed", "wind_gust"].includes(key) ) return base_string + color_string
    if(isNaN(val)) color_string = 'bg-green-500'
    else if(key == "temperature"){
      value = val
      if(units != "F"){
        value = (val * 9 / 5) + 32
      }
      var lowRange = getThresholdColor(value, thresholds["temperatureLowRange"])
      var highRange = getThresholdColor(value, thresholds["temperatureHighRange"])
      if(lowRange === "bg-green-500" && highRange === "bg-green-500"){
        color_string = "bg-green-500";
      }
      if(lowRange === "bg-yellow-500" || highRange === "bg-yellow-500"){
        color_string = "bg-yellow-500";
      }
      if(lowRange === "bg-red-500" || highRange === "bg-red-500"){
        color_string = "bg-red-500";
      }
    }
    else if(key == "Kp_index"){
      if (val <= 4) {
        color_string = 'bg-green-500'
      } else if (val > 4 && val <= 6) {
        color_string = 'bg-yellow-500'
      } else {
        color_string = 'bg-red-500'
      }
    }
    else{
      color_string = getThresholdColor(Number(val), thresholds[thresh_key[key]])
    }
    return base_string + color_string
  }

  // For tracking the current weather tab
  useEffect(() => {
    if (opened) {
      getData()
      setContent(<img src={chevron} alt="" />)
      setTransition('flex flex-row translate-x-0 transition-all duration-500')
    } else {
      setContent(<img src={chevronUp} alt="" />)
      setTransition('flex flex-row translate-x-52 transition-all duration-500')
    }
  }, [opened])
  // For tracking location change
  useEffect(() => {
    setLoad(false)
    if (opened) getData()
  }, [location])
  // For dismissing toasts after 3 seconds
  useEffect(() => {
    if (showToast) {
      const toastTimeout = setTimeout(() => {
        setShowToast(false)
      }, 6000)
      return () => clearTimeout(toastTimeout)
    }
  }, [showToast])

  const handleClick = () => {
    setOpener((prevVal) => !prevVal)
  }
  // HTML to image convert function
  const htmlToImageConvert = useCallback(() => {
    if (elementRef.current === null) {
      return
    }
    toPng(elementRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a')
        const fileName = 'CurrentWeather-' + Date.now() + '.png'
        link.download = fileName
        link.href = dataUrl
        link.click()
        setToastType(toastType.success)
        setToastMessage('PNG exported:' + fileName)
        setShowToast(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [elementRef])

  return (
    <>
      <div className={`absolute right-0 top-1/4 overflow-x-clip pointer-events-none`}>
        <div className={transition}>
          <button
            className="shrink w-12 h-52 p-2 rounded-l-lg bg-black pointer-events-auto"
            onClick={() => handleClick()}
            type="button"
          >
            <div className="-ml-14 w-fit h-fit -rotate-90 text-md font-small text-white  whitespace-nowrap">
              Current Conditions
              <div className="inline-block ml-2">{content}</div>
            </div>
          </button>
          <div className="bg-white font-small rounded-bl-lg p-1 h-fit w-52">
            <div className="bg-white w-full p-1" ref={elementRef}>
              V360° Best Estimate
              <ul>
                {weatherData &&
                  Object.entries(param_map).map(([key]) => (
                    <li key={key}>
                      <div className="m-1 p-1 flex flex-row bg-gray-300 text-sm rounded">
                        <div
                          className={
                            typeof weatherData[key] == 'undefined'
                              ? 'm-1 h-3.5 w-3.5 bg-gray-500'
                              : color_maping(key, weatherData[key]?.value, weatherData[key]?.units, loaded)
                          }
                        ></div>
                        <div className="ml-1 content-center">
                          {param_map[key]} :{' '}
                          {weatherData[key] && loaded ? (
                            <div className="inline-block ">
                              {' '}{weatherData[key]?.value}
                              {' '}{!isNaN(weatherData[key]?.value) && units_map[weatherData[key]?.units]}
                            </div>
                          ) : (
                            <div className="inline-block w-10 h-2 animate-pulse rounded bg-gray-600" />
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            {Object.keys(weatherData).length > 0 ? (
              <button
                type="button"
                onClick={htmlToImageConvert}
                className="text-sm w-max cursor-pointer rounded bg-gray-300 -mt-1 mx-auto block p-1 hover:bg-gray-500  pointer-events-auto"
              >
                Export Data
                <svg
                  className="w-4 h-4 ml-1 text-gray-800 dark:text-white inline-block"
                  aria-hidden="true"
                  xmlns="
                      http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                disabled
                className="text-sm w-max cursor-not-allowed rounded -mt-1 mx-auto block bg-gray-300 p-1 pointer-events-auto"
              >
                Export Data
                <svg
                  className="w-4 h-4 ml-1 text-gray-800 dark:text-white inline-block"
                  aria-hidden="true"
                  xmlns="
                      http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      {showToast ? (
        <Toast
          toastMessage={toastMessage}
          toastPosition={toastPosition.topRight}
          toastType={type}
          onClickCallback={toastCallbackFn}
        />
      ) : (
        <div></div>
      )}
    </>
  )
}
export default CurrentWeatherTab
